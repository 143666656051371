var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex flex-wrap align-center gap-3"},[_vm._v(" Address Information "),(_vm.addressData.isMonitoring)?_c('MonitoringButton'):_c('gl-icon',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 100 incoming transactions'),expression:"'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 100 incoming transactions'",arg:"tooltip",modifiers:{"top":true}}],attrs:{"height":24,"name":"def-report","width":24}})],1)]},proxy:true},{key:"body",fn:function(){return [(!_vm.hasTxs && !_vm.addressDataLoading)?_c('div',{staticClass:"wallet-address-wrap pa-2"},[_vm._v(" There are no transactions for this address ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Wallet address","loading":_vm.addressDataLoading,"value":`${(_vm.addressData && _vm.addressData.address) || _vm.address} / ${
            _vm.contractData.ens || '--'
          }`}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Type","loading":_vm.addressDataLoading,"value":"Contract"}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Token Balance","loading":_vm.addressDataLoading,"rate":_vm.addressData && _vm.addressData.price && _vm.formatByPrice(_vm.addressData.price),"rate-time":"current","rate-timestamp":_vm.addressData && _vm.addressData.priceTimestamp,"value":_vm.addressData
            && _vm.addressData.balance
            && `${_vm.toComaSeparate(String(_vm.restrictNumberAfterComma(_vm.addressData.balance, 8)))} ${_vm.addressData.symbol || _vm.selectedToken.symbol}`}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Number of TXS","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txCount))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx sent","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txOutputsCount))) || '0'}}),_c('InfoBlock',{attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx received","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txInputsCount))) || '0'}})],1)]},proxy:true}])}),_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Owner Information ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.isOwnerExist(_vm.addressData))?_c('div',[_c('ReportSectionBody',{attrs:{"data":_vm.addressData,"data-description":_vm.addressData.description,"entity-id":_vm.addressData.entityId,"label-tag":"Extra context tags towards the classification of the requested address","label-top":"Owner / Contract Name","label-type":"Type of entity that owns requested address","owner-name":`${
            (_vm.addressData && _vm.addressData.owner) ||
            (_vm.contractData && _vm.contractData.owner) ||
            '--'
          } / ${
            (_vm.addressData &&
              _vm.addressData.contractInfo &&
              _vm.addressData.contractInfo.name) ||
            (_vm.contractData &&
              _vm.contractData.contractInfo &&
              _vm.contractData.contractInfo.name) ||
            '--'
          }`,"tag-meta-data":_vm.addressData.meta,"type-data":_vm.addressData.type}}),_c('hr',{staticClass:"mt-2 mb-2 pale-grey-border"}),_c('EthContractEnsBlock',{attrs:{"address-data":_vm.addressData,"contract-data":_vm.contractData}})],1):_c('div',{staticClass:"pa-2"},[_vm._v(" Not identified ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }